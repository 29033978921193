<template>
    <div class="invoice" style="padding: 30px 40px">
        <div class="hand op btn-title" @click="addAddress">新增发票信息</div>
        <el-dialog :close-on-click-modal="false" :visible.sync="editShow" width="640px" :show-close="false" class="event-form">
            <div>
                <div class="top">
                    发票信息
                    <img src="../../shop/imgs/cancel.png" style="width: 28px; height: 28px;position: absolute;right: 30px;top: 14px;cursor: pointer;" @click="editShow = false">
                </div>
                <div style="padding: 20px">
                    <TicketForm @reTicket="getTicketList" :editInfo="address"></TicketForm>
                </div>
            </div>
        </el-dialog>

        <div style="margin-top: 50px" v-if="addressData.length > 0">
            <div class="btn-title">已保存的发票信息</div>
            <el-table
                border
                :data="addressData"
                style="width: 100%">
                <el-table-column
                    label="发票类型"
                    width="140"
                    align="center">
                    <template slot-scope="scope">
                        <span v-if="scope.row.type === 1" style="margin-left: 10px">个人发票</span>
                        <span v-if="scope.row.type === 2" style="margin-left: 10px">单位发票</span>
                        <span v-if="scope.row.type === 3" style="margin-left: 10px">专用发票</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="发票抬头"
                    width="360"
                    align="center">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">
                            {{scope.row.addressInfo.title}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="联系电话"
                    width="180"
                    align="center">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row.addressInfo.phone }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="160" align="center">
                    <template slot-scope="scope">
                        <span class="hand op" @click="editAddress(scope.row)" style="color: #1479FF;">编辑</span>
                        <span style="margin: 0 10px">|</span>
                        <span class="hand op" @click="deleteAddress(scope.row.id)">删除</span>
                    </template>
                </el-table-column>
                <el-table-column width="160" align="center">
                    <template slot-scope="scope">
                        <span class="hand op" @click="setDefaultAddress(scope.row.id)" v-if="scope.row.isDefault === 0" style="color: #1479FF">设为默认</span>
                        <span v-if="scope.row.isDefault === 1" style="background-color: #1479FF; padding: 2px 10px; border-radius: 30px; color: #FFFFFF">默认</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
import TicketForm from "./pay/ticketForm";
export default {
    name: "InvoiceManage",
    components: {TicketForm},
    computed:{
        user(){
            return this.$store.state.user;
        }
    },
    data() {
        return {
            addressData: [],
            address: {},

            editShow: false,
        }
    },

    mounted() {
        this.getTicketList()
    },

    methods: {
        // 设置默认地址
        setDefaultAddress(id) {
            this.newApi.setInvoiceDefault({id: id}).then(res => {
                if (res.isSuccess === 1) {
                    this.utils.sus(res.data)
                    this.getTicketList()
                }
            })
        },

        // 获取发票列表
        getTicketList() {
            this.editShow = false;
            this.newApi.getInvoiceList().then(res => {
                this.addressData = res.data
            })
        },



        // 添加地址
        addAddress() {
            if(this.user.realNameStatus != 2 ){
                return this.utils.msg("请先完成实名认证");
            }
            this.editShow = true;
        },

        // 编辑
        editAddress(item) {
            this.address = item
            this.editShow = true;
        },

        // 删除
        deleteAddress(id) {
            let that = this;
            this.utils.confirm('确认删除此发票信息？', function () {
                that.newApi.deleteInvoice({id: id}).then(res => {
                    if (res.isSuccess === 1) {
                        that.utils.sus(res.data);
                        that.getTicketList()
                    }
                })
            })
        },
    }
}
</script>

<style scoped>
.invoice .btn-title {
    font-size: 18px;
    font-weight: 600;
    color: #2970FF;
    margin-bottom: 20px;
}

.invoice .title {
    font-size: 16px;
    font-weight: 600;
}

.event-form .top {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #444444;
    border: 1px solid #CECFD0;
    height: 50px;
    box-sizing: border-box;
    line-height: 50px;
}

/deep/ .event-form .el-dialog__header {
    padding: 0;
}

/deep/ .event-form .el-dialog__body {
    padding: 0;
}
</style>
