<template>
    <div class="extract-record">
        <div style="width: 100%;height: 750px;">
            <el-table
                :data="cashflow.list"
                :header-cell-style="{background:'#F4F4F4',color:'#444444'}"
                style="width: 100%; font-size: 18px">
                <el-table-column
                    label="提现卡"
                    width="240"
                    align="center">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row.cardId }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="提现金额"
                    width="240"
                    align="center">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">+{{ scope.row.realAmt }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="提现状态"
                    width="140"
                    align="center">
                    <template slot-scope="scope">
                    <span style="margin-left: 10px">
                       <el-tag v-if="scope.row.status === 'succeeded'" type="success" size="small" style="background: #67c23a;color: #FFFFFF">提现成功</el-tag>
                        <el-tag v-if="scope.row.status === 'pending'" type="primary" size="small" style="background: #409eff;color: #FFFFFF">提现中</el-tag>
                        <el-tag v-if="scope.row.status === 'failed'" type="danger" size="small" style="background: #f56c6c;color: #FFFFFF">提现失败</el-tag>
                    </span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="订单号"
                    align="center">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row.orderNo }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="提现时间"
                    width="240"
                    align="center">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row.createTime }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style="display: flex; justify-content: flex-end; align-items: center; width: 100%; height: 40px; margin-top: 10px;">
            <el-pagination
                background
                layout="prev, pager, next"
                :total="cashflow.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ExtractRecord",
        data() {
            return {
                recordData: [
                    {
                        time: '2022-05-09  20:30:00',
                        card: '1657（尾号）',
                        money: '+888.00',
                        sum: '2088.00'
                    },
                    {
                        time: '2022-05-09  20:30:00',
                        card: '1657（尾号）',
                        money: '+888.00',
                        sum: '2088.00'
                    },
                    {
                        time: '2022-05-09  20:30:00',
                        card: '1657（尾号）',
                        money: '+888.00',
                        sum: '2088.00'
                    },
                    {
                        time: '2022-05-09  20:30:00',
                        card: '1657（尾号）',
                        money: '+888.00',
                        sum: '2088.00'
                    }
                ],
                cashflow:{
                    list:[],
                    total:0
                }
            }
        },

        mounted() {
            this.cashFlow()
        },

        methods: {
            //    查询取现流水
            cashFlow(){
                let that = this;
                that.newApi.cashFlow({type:0,page:1,pageSize:10}).then((res)=>{
                    that.cashflow = res.data;
                }).catch((err)=>{
                    console.log(err)
                })
            },
        }
    }
</script>

<style scoped>
.push-flow{
    padding: 10px 10px 0px 10px;
}
</style>
