<template>
    <div class="pull-flow">
        <div style="width: 100%;height: 750px;">
            <el-table
                :data="info.list"
                :border="false"
                :header-cell-style="{background:'#F4F4F4', color:'#444444'}"
                style="width: 100%;font-size: 18px">
                <el-table-column
                    width="220"
                    align="center">
                    <template slot="header" slot-scope="scope">
                        <el-select size="small" v-model="value" @change="handleSelectChange" clearable placeholder="请选择">
                            <el-option
                                v-for="item in info.nameList"
                                :key="item.payType"
                                :label="item.name"
                                :value="item.payType">
                            </el-option>
                        </el-select>
                    </template>
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="金额（元）"
                    width="240"
                    align="center">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">+{{ scope.row.value }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="订单号"
                    align="center">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row.orderNo }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    label="收益时间"
                    width="260"
                    align="center">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row.time }}</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div style="display: flex; justify-content: flex-end; align-items: center; width: 100%; height: 40px; ">
            <el-pagination
                background
                layout="prev, pager, next"
                :page-size="pageSize"
                @current-change="handleCurrentChange"
                :total="info.total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PullFlow",
        data() {
            return {
                payType: 0,
                value: '',
                tagColors: ['#DE5A4C', '#ED9A40', '#DD6A8E', '#7C65C4', '#58B8DA', '#8496CA', '#9EC656', '#84BCAC', '#F6CE03'],
                info: {
                    list:[],
                    nameList:[],
                    total:0
                },
                flowtype:'0',
                page:1,
                pageSize:14,
            }
        },

        mounted() {
            this.revenueFlow(this.payType)
        },

        methods: {
            //监听el-select
            handleSelectChange(val) {
                if(this.value === ''){
                    this.payType = 0;
                }
                this.payType = val;
                this.revenueFlow();
            },
            // 查询收益流水
            revenueFlow(){
                let that = this;
                that.newApi.revenueFlow({payType:that.payType,page:that.page,pageSize:that.pageSize}).then((res)=>{
                    if(res.isSuccess == 1){
                        that.info = res.data;
                    }

                }).catch((err)=>{
                    console.log(err)
                })
            },
            //分页
            handleCurrentChange(val) {
                this.page = val;
                this.revenueFlow();
            },
            // 点击查看全部
            codetype(type){
                this.payType = type
                this.revenueFlow();
            }

        }
    }
</script>

<style scoped>
    .pull-flow{
        padding: 10px 10px 0px 10px;
    }
</style>
