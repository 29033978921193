<template>
    <div class="tickForm">
        <div style="display: flex; margin-bottom: 20px">
            <div class="btn hand op" @click="checkoutType(1)" :class="{'select-border': type === 1}" style="margin-right: 20px">电子普通发票</div>
            <div class="btn hand op" @click="checkoutType(2)" :class="{'select-border': type === 2}">专用发票</div>
        </div>

        <div v-if="type === 1">
            <el-form ref="invoice" :model="invoice.invoiceInfo" :rules="invoiceRules" label-width="100px">
                <el-form-item label="发票抬头" prop="type">
                    <div style="display: flex;">
                        <div class="btn hand op" @click="changeType(1)" :class="{'select-border': invoice.type === 1}" style="padding: 2px 0; margin-top: 6px;height: 20px; line-height:20px; width: 80px; margin-right: 20px">个人</div>
                        <div class="btn hand op" @click="changeType(2)" :class="{'select-border': invoice.type === 2}" style="padding: 2px 0; margin-top: 6px;height: 20px; line-height:20px; width: 80px;">单位</div>
                    </div>
                </el-form-item>
                <el-form-item label="发票内容">
                    <div style="width: 84px; padding: 2px 0; margin-top: 6px; line-height: 20px; text-align: center; color: #FF0000; border: 1px solid #FF0000">
                        商品明细
                    </div>
                </el-form-item>

                <el-form-item label="收票方名称" prop="titles">
                    <el-input size="small"  v-model="invoice.invoiceInfo.titles"></el-input>
                </el-form-item>
                <el-form-item label="收票方手机" prop="phones">
                    <el-input size="small" v-model="invoice.invoiceInfo.phones"></el-input>
                </el-form-item>
                <el-form-item label="收票方邮箱" prop="emails">
                    <el-input size="small" v-model="invoice.invoiceInfo.emails"></el-input>
                </el-form-item>
                <div v-if="invoice.type === 2">
                    <el-form-item label="纳税人识别号" prop="dutyNumbers">
                        <el-input size="small" v-model="invoice.invoiceInfo.dutyNumbers"></el-input>
                    </el-form-item>
                    <el-form-item label="开户银行" prop="bank">
                        <el-input size="small" v-model="invoice.invoiceInfo.more.bank"></el-input>
                    </el-form-item>
                    <el-form-item label="开户行号" prop="account">
                        <el-input size="small" v-model="invoice.invoiceInfo.more.account"></el-input>
                    </el-form-item>
                    <el-form-item label="注册地址" prop="address">
                        <el-input size="small" v-model="invoice.invoiceInfo.more.address"></el-input>
                    </el-form-item>
                </div>
            </el-form>
<!--            <div>-->
<!--                <AddressSelect @change="selectCity" :address ="invoice.invoiceInfo.address" :lv="3" :info="true" :multiple="false"></AddressSelect>-->
<!--            </div>-->
            <el-checkbox v-model="invoice.isDefault" :true-label="1" :false-label="0">默认发票地址</el-checkbox>

            <div style="display: flex; justify-content: center; margin-top: 20px">
                <div class="hand op bottom-btn" style="margin-right: 10px;background-color: #2970FF; color: #FFFFFF" @click="saveTicket">保存</div>
                <div class="hand op bottom-btn" style="background-color: #F5F5F5" @click="deleteTicket">取消</div>
            </div>

        </div>

        <div v-if="type === 2">
            <el-form ref="majorInvoice" :model="majorInvoice.invoiceInfo" :rules="majorInvoiceRules" label-width="120px">
                <el-form-item label="开票方式">
                    <div style="width: 84px; padding: 2px 0; margin-top: 6px; line-height: 20px; text-align: center; color: #FF0000; border: 1px solid #FF0000">
                        订单完成后
                    </div>
                </el-form-item>
                <el-form-item label="发票内容">
                    <div style="width: 84px; padding: 2px 0; margin-top: 6px; line-height: 20px; text-align: center; color: #FF0000; border: 1px solid #FF0000">
                        商品明细
                    </div>
                </el-form-item>
                <el-form-item label="单位名称" prop="title">
                    <el-input size="small"  v-model="majorInvoice.invoiceInfo.title"></el-input>
                </el-form-item>
                <el-form-item label="纳税人识别码" prop="dutyNumber">
                    <el-input size="small" v-model="majorInvoice.invoiceInfo.dutyNumber"></el-input>
                </el-form-item>
                <el-form-item label="注册地址" prop="address">
                    <el-input size="small" v-model="majorInvoice.invoiceInfo.address"></el-input>
                </el-form-item>
                <el-form-item label="注册电话" prop="phone">
                    <el-input size="small" v-model="majorInvoice.invoiceInfo.phone"></el-input>
                </el-form-item>
                <el-form-item label="开户银行" prop="bank">
                    <el-input size="small" v-model="majorInvoice.invoiceInfo.bank"></el-input>
                </el-form-item>
                <el-form-item label="银行账户" prop="bankaAcount">
                    <el-input size="small" v-model="majorInvoice.invoiceInfo.bankaAcount"></el-input>
                </el-form-item>
                <el-form-item label="收票人邮箱" prop="email">
                    <el-input size="small" v-model="majorInvoice.invoiceInfo.email"></el-input>
                </el-form-item>
                <el-form-item label="收票人姓名" prop="ticketName">
                    <el-input size="small" v-model="majorInvoice.invoiceInfo.ticketName"></el-input>
                </el-form-item>
                <el-form-item label="收票人手机" prop="ticketPhone">
                    <el-input size="small" v-model="majorInvoice.invoiceInfo.ticketPhone"></el-input>
                </el-form-item>
                <el-form-item label="收票人地址" prop="ticketAddress">
                    <div>
                        <AddressSelect @change="selectCity" :address ="majorInvoice.invoiceInfo.ticketAddress" :lv="3" :info="true" :multiple="false"></AddressSelect>
                    </div>
                </el-form-item>
            </el-form>

            <el-checkbox v-model="invoice.isDefault" :true-label="1" :false-label="0">默认收货地址</el-checkbox>

            <div style="display: flex; justify-content: center; margin-top: 20px">
                <div class="hand op bottom-btn" v-if="!editInfo" style="margin-right: 10px;background-color: #2970FF; color: #FFFFFF" @click="saveTicket">保存</div>
                <div class="hand op bottom-btn" v-else style="margin-right: 10px;background-color: #2970FF; color: #FFFFFF" @click="updateAddress">编辑</div>
                <div class="hand op bottom-btn" style="background-color: #F5F5F5" @click="deleteTicket">取消</div>
            </div>

        </div>
    </div>

</template>

<script>

import AddressSelect from "../../../../components/common/city/AddressSelect";
export default {
    name: "ticketForm",
    components: {AddressSelect},
    data() {
        return {
            type: 1,
            invoice: {
                type: 1,
                invoiceInfo: {
                    titles: '',
                    phones: '',
                    emails: '',
                    contentType: 1,
                    dutyNumbers: '',
                    more: {
                        bank: '',
                        address: '',
                        acccount: '',
                        tel: '',
                    },
                },
                isDefault: 0,
            },

            invoiceRules: {
                titles: [
                    { required: true, message: '请输入收货人姓名', trigger: 'blur' },
                ],
                dutyNumbers: [
                    { required: true, message: '请输入纳税人识别码', trigger: 'blur' },
                ],
                phones: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
                ],
                emails: [
                    { required: true, message: '请输入邮箱号码', trigger: 'blur' },
                ]
            },

            majorInvoice: {
                type: 3,
                invoiceInfo: {
                    type: 3,
                    title: '',
                    phone: '',
                    email: '',
                    dutyNumber: '',
                    bank: '',
                    address: '',
                    bankaAcount: '',
                    ticketName: '',
                    ticketPhone: '',
                    ticketAddress: {},
                },
                isDefault: 0,
            },

            majorInvoiceRules: {
                title: [
                    { required: true, message: '抬头不能为空', trigger: 'blur' },
                ],
                phone: [
                    { required: true, message: '请输入手机号码', trigger: 'blur' },
                    { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
                ],
                emails: [
                    { required: true, message: '请输入邮箱号码', trigger: 'blur' },
                ],
                dutyNumber: [
                    { required: true, message: '请输入纳税人识别码', trigger: 'blur' },
                ],
                bank: [
                    { required: true, message: '请输入开户银行', trigger: 'blur' },
                ],
                bankaAcount: [
                    { required: true, message: '请输入银行卡号', trigger: 'blur' },
                ],
                address: [
                    { required: true, message: '请输入注册地址', trigger: 'blur' },
                ],
                ticketPhone: [
                    { required: true, message: '请输入收票人手机号码', trigger: 'blur' },
                    { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
                ],
                ticketName: [
                    { required: true, message: '请输入收票人姓名', trigger: 'blur' },
                ],
                ticketAddress: [
                    { required: true, message: '请输入收票人地址', trigger: 'blur' },
                ],
            },
        }
    },
    computed: {
        user() {
            return this.$store.state.user
        }
    },
    props: {
        editInfo: {
            type: Object,
        }
    },
    watch: {
        editInfo: {
            deep: true,
            handler(newData) {
                this.type = 1;
                if (newData.type === 1) {
                    this.invoice = {
                        type: newData.type,
                        invoiceInfo: {
                            titles: newData.addressInfo.title,
                            phones: newData.addressInfo.phone,
                            emails: newData.addressInfo.email,
                            contentType: 1,
                        },
                        isDefault: newData.isDefault,
                    };
                }
                if (newData.type === 2) {
                    this.invoice = {
                        type: newData.type,
                        invoiceInfo: {
                            titles: newData.addressInfo.title,
                            phones: newData.addressInfo.phone,
                            emails: newData.addressInfo.email,
                            dutyNumbers: newData.addressInfo.dutyNumber,
                            more: {
                                bank: newData.addressInfo.more.bank,
                                address: newData.addressInfo.more.address,
                                acccount: newData.addressInfo.more.acccount,
                                tel: newData.addressInfo.more.tel,
                            },
                            contentType: 1,
                        },
                        isDefault: newData.isDefault,
                    };
                }
                if (newData.type === 3) {
                    this.type = 2;
                    this.majorInvoice = {
                        type: newData.type,
                        invoiceInfo: newData.addressInfo,
                        isDefault: newData.isDefault,
                    };
                }
            }
        }
    },
    created: function () {

    },
    mounted: function () {
        this.type = 1;
        if (this.editInfo.type === 1) {
            this.invoice = {
                type: this.editInfo.type,
                invoiceInfo: {
                    titles: this.editInfo.addressInfo.title,
                    phones: this.editInfo.addressInfo.phone,
                    emails: this.editInfo.addressInfo.email,
                    contentType: 1,
                },
                isDefault: this.editInfo.isDefault,
            };
        }
        if (this.editInfo.type === 2) {
            this.invoice = {
                type: this.editInfo.type,
                invoiceInfo: {
                    titles: this.editInfo.addressInfo.title,
                    phones: this.editInfo.addressInfo.phone,
                    emails: this.editInfo.addressInfo.email,
                    dutyNumbers: this.editInfo.addressInfo.dutyNumber,
                    more: {
                        bank: this.editInfo.addressInfo.more.bank,
                        address: this.editInfo.addressInfo.more.address,
                        acccount: this.editInfo.addressInfo.more.acccount,
                        tel: this.editInfo.addressInfo.more.tel,
                    },
                    contentType: 1,
                },
                isDefault: this.editInfo.isDefault,
            };
        }
        if (this.editInfo.type === 3) {
            this.type = 2;
            this.majorInvoice = {
                type: this.editInfo.type,
                invoiceInfo: this.editInfo.addressInfo,
                isDefault: this.editInfo.isDefault,
            };
        }
    },
    methods: {
        // 修改类型
        changeType(type) {
            this.invoice = {
                type: type,
                invoiceInfo: {
                    titles: '',
                    phones: '',
                    emails: '',
                    contentType: 1,
                    dutyNumbers: '',
                    more: {
                        bank: '',
                        address: '',
                        acccount: '',
                        tel: '',
                    },
                },
                isDefault: 0,
            };
            this.majorInvoice = {
                type: type,
                invoiceInfo: {
                    type: 3,
                    title: '',
                    phone: '',
                    email: '',
                    dutyNumber: '',
                    bank: '',
                    address: '',
                    bankaAcount: '',
                    ticketName: '',
                    ticketPhone: '',
                    ticketAddress: {},
                },
                isDefault: 0,
            }
        },

        // 修改类型
        checkoutType(type) {
            this.type = type
        },

        deleteTicket() {
            this.$emit('reTicket');
        },

        // 选择地址
        selectCity(item) {
            this.invoice.invoiceInfo.address = item
        },

        // 保存发票信息
        saveTicket() {
            let that = this;
            if (this.type === 1) {
                this.$refs['invoice'].validate((valid) => {
                    if (valid) {
                        let param = {};
                        param.type = this.invoice.type;
                        param.isDefault = this.invoice.isDefault;
                        param.invoiceInfo = {};
                        param.invoiceInfo.contentType = 1;
                        param.invoiceInfo.type = this.invoice.type;
                        param.invoiceInfo.title = this.invoice.invoiceInfo.titles;
                        param.invoiceInfo.phone = this.invoice.invoiceInfo.phones;
                        param.invoiceInfo.email = this.invoice.invoiceInfo.emails;
                        if (param.type === 2) {
                            param.invoiceInfo.dutyNumber = this.invoice.invoiceInfo.dutyNumbers;
                            param.invoiceInfo.more = this.invoice.invoiceInfo.more;
                        }
                        param.invoiceInfo = JSON.stringify(param.invoiceInfo);
                        this.newApi.addInvoice(param).then(res => {
                            if (res.isSuccess === 1) {
                                this.utils.sus(res.data);
                                that.$emit('reTicket');
                            }
                        })
                    }
                })
            }
            if (this.type === 2) {
                this.$refs['majorInvoice'].validate((valid) => {
                    if (valid) {
                        let param = {};
                        param.type = this.majorInvoice.type;
                        param.isDefault = this.majorInvoice.isDefault;
                        param.invoiceInfo = JSON.stringify(this.majorInvoice.invoiceInfo);
                        this.newApi.addInvoice(param).then(res => {
                            if (res.isSuccess === 1) {
                                this.utils.sus(res.data);
                                that.$emit('reTicket');
                            }
                        })
                    }
                })
            }
        },

        // 更新发票信息
        updateAddress() {
            let that = this;
            if (this.type === 1) {
                this.$refs['invoice'].validate((valid) => {
                    if (valid) {
                        let param = {};
                        param.id = this.editInfo.id;
                        param.type = this.invoice.type;
                        param.isDefault = this.invoice.isDefault;
                        param.invoiceInfo = {};
                        param.invoiceInfo.contentType = 1;
                        param.invoiceInfo.type = this.invoice.type;
                        param.invoiceInfo.title = this.invoice.invoiceInfo.titles;
                        param.invoiceInfo.phone = this.invoice.invoiceInfo.phones;
                        param.invoiceInfo.email = this.invoice.invoiceInfo.emails;
                        if (param.type === 2) {
                            param.invoiceInfo.dutyNumber = this.invoice.invoiceInfo.dutyNumbers;
                            param.invoiceInfo.more = this.invoice.invoiceInfo.more;
                        }
                        param.invoiceInfo = JSON.stringify(param.invoiceInfo);
                        this.newApi.updateInvoice(param).then(res => {
                            if (res.isSuccess === 1) {
                                this.utils.sus(res.data);
                                that.$emit('reTicket');
                            }
                        })
                    }
                })
            }
            if (this.type === 2) {
                this.$refs['majorInvoice'].validate((valid) => {
                    if (valid) {
                        let param = {};
                        param.id = this.editInfo.id;
                        param.type = this.majorInvoice.type;
                        param.isDefault = this.majorInvoice.isDefault;
                        param.invoiceInfo = JSON.stringify(this.majorInvoice.invoiceInfo);
                        this.newApi.updateInvoice(param).then(res => {
                            if (res.isSuccess === 1) {
                                this.utils.sus(res.data);
                                that.$emit('reTicket');
                            }
                        })
                    }
                })
            }
        },
    }
}
</script>

<style scoped>
    .tickForm {
        width: 100%;
        height: 100%;
    }

    .tickForm .btn {
        width: 100px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        border: 1px solid #BDBFC1;
    }

    .tickForm .bottom-btn {
        width: 100px;
        height: 30px;
        line-height: 30px;
        border-radius: 5px;
        text-align: center;
    }

    .tickForm .select-border {
        border: 2px solid #FF0000;
        color: #FF0000;
    }

</style>