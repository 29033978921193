<template>
   <div style="width:calc(100% - 10px);height: 630px;margin: 0 auto; border: 1px solid #DCDFE6;margin-top: 5px;">
        <div style="width: 100%;height: 60px;border-bottom: 1px solid #DCDFE6;display: flex;">
            <div style="display: flex;width: calc(100% - 60px);height: 30px;margin: 0  auto;margin-top: 15px;">
                <div style="width: 184px;line-height: 30px;font-size: 18px;font-weight: 600;color: #4D4D4D;">提取余额到银行卡</div>
                <div style="width: calc(100% - 184px - 65px);line-height: 30px;color: #C6C4C6;">余额：<span style="font-size: 18px;color: #FC491E;">{{acctBalance.info.acct_balance}}</span> 元</div>
                <div @click="withdrawaShow = true" @mouseover="withdrawaColor = true" @mouseleave="withdrawaColor = false" :style="withdrawaColor == true?'color: #FBC11A':'color: #5088CC;'" style="width: 65px;line-height: 30px;font-size: 16px;cursor: pointer;">提现记录</div>
            </div>
        </div>

       <div v-if="withdrawaResult == -2">
           <div style="width: 100%;line-height: 55px;text-align:center;margin-top: 35px;">
               请先绑定银行卡进行提现
           </div>
           <div style="width: 100%;line-height: 55px;text-align:center;">
               <el-button @click="bindCard" type="danger">立即绑定</el-button>
           </div>
       </div>

       <!--提现中-->
       <div v-if="withdrawaResult == 0">
           <div style="width: 100%;height: auto;display: flex;">
               <div style="display: flex;width: calc(100% - 60px);height: auto;margin: 0  auto;margin-top: 40px;">
                   <div style="width: 144px;line-height: 40px;text-align: right;">提现至:</div>
                   <div style="width: calc(100% - 184px);height: auto;">
                       <div v-for="(item, index) in cardList" :key="index" style="width: calc(100% - 2px); height: 46px; display: flex; border: 1px solid #DCDFE6; font-size: 14px; margin-bottom: 15px; margin-left: 20px;">
                           <div style="margin-top: 7px; margin-left: 30px;">
                               <el-radio v-model="radio" :label="index" @change="radioChange(index)">{{item.bankName}}</el-radio>
                           </div>
                           <div style="margin-top: 7px;">
                               <img style="width: 30px; height: 20px; margin-top: 6px;" :src="item.bankUrl">
                           </div>
                           <div style="width: 500px; line-height: 34px; margin-top: 7px; padding-left: 5px;">
                               尾号：{{item.cardId}} <span style="color: #5088CC;">(快捷)</span>
                           </div>
                           <div style="line-height: 34px; margin-top: 7px; padding-left: 5px; color: #c1c1c1;">
                               单笔50000.00元 每日
                           </div>
                       </div>

                   </div>
               </div>
           </div>
           <div style="width: 100%;">
               <div style="display: flex;width: calc(100% - 60px);height: auto;margin: 0  auto;margin-top: 20px;">
                   <div style="width: 144px;line-height: 40px;text-align: right;">提现金额:</div>
                   <div style="width: 144px;line-height: 40px;margin-left: 20px;">
                       <el-input type="number" v-model="money" @input="checkMoney" placeholder="请输入金额" inputmode="numeric"></el-input>
                   </div>
                   <div style="width: 264px;line-height: 40px;margin-left: 20px;font-size: 12px;color: #c1c1c1;">
                       (最多可提现:<span style="color: #1D82FE;">{{acctBalance.info.acct_balance}}元</span>,次日到账不收取手续费)
                   </div>
               </div>
               <div style="display: flex;width: calc(100% - 60px);height: auto;margin: 0  auto;margin-top: 40px;">
                   <div style="width: 144px;line-height: 40px;text-align: right;">安全密码:</div>
                   <div style="width: 144px;line-height: 40px;margin-left: 20px;">
                       <el-input v-model="payPwd" autocomplete="new-password" type="password" placeholder="请输入安全密码"></el-input>
                   </div>
                   <div @click="pwdShow = true" @mouseover="pwdColor = true" @mouseleave="pwdColor = false" :style="pwdColor == true?'color: red':'color: #5088CC;'" style="width: 144px;line-height: 40px;margin-left: 20px;font-size: 12px;cursor: pointer;">
                       忘记密码
                   </div>
               </div>
               <div style="display: flex;width: calc(100% - 60px);height: auto;margin: 0  auto;margin-top: 40px;">
                   <div style="width: 144px;line-height: 40px;text-align: right;"></div>
                   <div style="width: 144px;line-height: 40px;margin-left: 20px;">
                       <el-button @click="alpplyBankCarCash" type="primary">下一步</el-button>
                   </div>
               </div>
           </div>
       </div>

       <!--提现成功提示-->
       <div v-if="withdrawaResult == 1">
           <div style="width: 100%;height: 96px;background: #FFF9D9;display: flex;">
               <div style="width: 40px;height: 40px;margin-top: 28px;margin-left: 30px;">
                   <img style="width: 100%;height: 100%;" src="@/assets/imgs/other/djs.png">
               </div>
               <div style="width: calc(100% - 75px);line-height: 96px;padding-left: 15px;font-weight: bold;">
                   提现申请已提交，等待银行处理。
               </div>
           </div>
           <div style="width: calc(100% - 85px);line-height: 35px;padding-left: 35px;font-size: 14px;color: #888A88;">如果银行信息填写错误，导致提现失败，资金将自动退还到您的账户余额。</div>
           <div style="width: 100%;height: 60px;display: flex;margin-top: 30px;">
               <div style="width: calc(30% - 35px);height: 100%;padding-left: 35px;color: #62B312;">
                   <div style="width: 100%;font-size: 14px;font-weight: bold;">提现申请</div>
                   <div style="width: 100%;font-size: 14px;color: #888A88;">{{cashResult.cashTime}}</div>
               </div>
               <div style="width: 30%;height: 100%;color: #62B312;">
                   <div style="width: 100%;font-size: 14px;font-weight: bold;">银行处理</div>
                   <div style="width: 100%;font-size: 14px;color: #888A88;">{{cashResult.cashTime}}</div>
               </div>
               <div style="width: 30%;height: 100%;color: #62B312;">
                   <div style="width: 100%;font-size: 14px;color: #888A88;">提现成功</div>
                   <div style="width: 100%;font-size: 14px;color: #888A88;">预计{{cashResult.expectTime}}后到账</div>
               </div>
           </div>
           <div style="width: calc(100% - 85px);line-height: 35px;padding-left: 35px;"><el-progress :percentage="35" status="warning"></el-progress></div>
           <div style="width: calc(100% - 85px);line-height: 65px;padding-left: 35px;font-size: 14px;color: #888A88;display: flex;">
               <div @click="continueToWithdraw" style="width: 70px;color: blue;cursor: pointer;">继续提现</div>
               <div @click="withdrawaShow = true" style="width: 100px;color: blue;cursor: pointer;">查看提现记录</div>
               <div @click="a('/')" style="width: 100px;color: blue;cursor: pointer;">返回首页</div>
           </div>
       </div>

       <!--提现记录弹窗-->
       <el-dialog title="提现记录" :close-on-click-modal="false" :visible.sync="withdrawaShow" v-if="withdrawaShow == true" width="60%">
            <ExtractRecord></ExtractRecord>
       </el-dialog>

       <!--忘记密码弹窗-->
       <el-dialog :close-on-click-modal="false" :visible.sync="pwdShow" width="22%">
           <ForgetPwd @change="pwdShow = false"></ForgetPwd>
       </el-dialog>
   </div>
</template>

<script>
import ExtractRecord from "@/pages/user/components/ExtractRecord.vue";
import ForgetPwd from "@/components/common/Pay/forgetPwd.vue";
export default {
    name: "PutMoney",
    components: {ExtractRecord,ForgetPwd},
    data() {
        return {
            cardList:[],
            stCardInfo:{},
            selectInfo:{},
            stCount:0,
            withdrawaResult: -3, //0提现中 -2未绑卡 -1提现失败 1提现成功
            radio: 0,
            isChenkCard:false,
            input: '',
            withdrawaShow:false,
            withdrawaColor:false,
            pwdShow:false,
            pwdColor:false,
            money:'',
            payPwd:'',
            cashResult:{},
        }
    },

    props: {
        acctBalance:{
            type:Object,
            default() {
                return {};
            }
        }
    },

    computed:{
        user() {
            return this.$store.state.user;
        },
    },

    mounted() {
        this.getAdaPayBanCardList();
    },

   methods:{
        //跳转绑卡
       bindCard() {
           this.$emit('change',{accType:this.acctBalance.accType,link:6});
       },

       //获取绑定的银行卡列表
       getAdaPayBanCardList(){
         var that = this;
         that.newApi.getAdaPayBanCardList().then((res) => {
             if(res.isSuccess == 1){
                 that.cardList = res.data;
                 if(that.cardList.length == 0){
                     that.withdrawaResult = -2;
                     return false;
                 }else{
                     that.withdrawaResult = 0;
                 }
                 for (let i = 0; i < that.cardList.length; i++) {
                     if(that.cardList[i].isSt == true){
                         that.stCardInfo = that.cardList[i];
                         that.stCount = 1;
                         return false;
                     }
                     //执行到最后一次 如果没有结算卡 自动开通结算
                     if(i == that.cardList.length - 1) {
                         if(that.stCount == 0){
                             that.settleAccountUser(that.cardList[0]);
                         }
                     }
                 }
             }
         })
       },

        //检查金额
       checkMoney(){
          if(this.money > this.acctBalance.info.acct_balance){
            this.$message.error('提现金额不能大于账户余额');
            this.money = this.acctBalance.info.acct_balance;
          }
       },

       //选择提现卡
       radioChange(index){
           var that = this;
           that.selectInfo = that.cardList[index];
       },

       //选择结算卡
       checkedSt() {
           var that = this;
           return new Promise((resolve, reject) => {
               if (that.selectInfo.isSt == false) {
                   if (that.stCount == 1) {
                       that.newApi.deleteSettleAccount({ tokenNo: that.stCardInfo.tokenNo }).then((res) => {
                           if (res.isSuccess == 1) {
                               that.newApi.settleAccountUser({tokenNo:that.selectInfo.tokenNo}).then((res) => {
                                   if(res.isSuccess == 1){
                                       // 重置所有cardList的checked状态为false
                                       that.cardList.forEach(card => card.isSt = false);
                                       that.selectInfo.isSt = true;
                                       that.stCardInfo = that.selectInfo;
                                       that.stCount = 1;
                                       that.$emit('change');
                                       resolve();
                                   }
                               })
                           } else {
                               reject(new Error('Delete settle account failed'));
                           }
                       })
                   } else {
                       that.settleAccountUser(that.selectInfo).then(() => {
                           // 重置所有cardList的checked状态为false
                           that.cardList.forEach(card => card.isSt = false);
                           that.selectInfo.isSt = true;
                           that.stCardInfo = that.selectInfo;
                           resolve();
                       })
                   }
               } else {
                   resolve();
               }
           });
       },


       // 创建结算账户对象
       settleAccountUser(carInfo){
           var that = this;
           var params = {};
           params.tokenNo = carInfo.tokenNo;
           that.newApi.settleAccountUser(params).then((res) => {
               if(res.isSuccess == 1){
                   that.stCardInfo = carInfo;
                   that.stCount = 1;
                   that.$emit('change');
               }
           })
       },

       //提现提交
       alpplyBankCarCash(){
           var that = this;
           if (this.money == '') {
               this.$message.error('请输入提现金额');
               return false;
           }
           if (this.payPwd == '') {
               this.$message.error('请输入安全密码');
               return false;
           }
           that.utils.confirm("取现到账方式为交易日的下一个工作日10:30后到账",function (){
               Promise.all([that.checkedSt()]).then(() => {
                   var params = {};
                   params.cashAmt = that.utils.keepTwoDecimalFull(that.money);
                   params.accountType = that.acctBalance.accType;
                   params.payPwd = that.payPwd;
                   that.openLoading('正在提现...');
                   that.newApi.alpplyBankCarCash(params).then((res) => {
                       if(res.isSuccess == 1){
                           that.$emit('change',{accType:that.acctBalance.accType,link:9});
                           that.money = '';
                           that.payPwd = '';
                           that.cashResult = res.data;
                           that.withdrawaResult = 1;
                       }
                   })
               }).catch(error => {
                   console.error("An error occurred: ", error);
               });
           })
       },

       //继续提现
       continueToWithdraw(){
           this.money = '';
           this.payPwd = '';
           this.withdrawaResult = 0;
       }
   }
}
</script>

<style scoped>

</style>
