<template>
  <div class="member">
    <div style="width: 100%; height: 80px; background-color: #FFFFFF">
      <div style="display: flex; justify-content: space-between; padding-top: 16px; width: 1500px; margin: 0 auto">
        <img src="@/pages/user/imgs/i3484.png" style="width: 334px; height: 49px">
        <div>
          <AvatarMenu></AvatarMenu>
        </div>
      </div>
    </div>
    <div class="body">
      <div style="width: 300px;min-height: 810px;background-color: #FFFFFF;float: left;">
        <div style="height: 26px;font-size: 20px;">
            <div v-if="user.userType == 1 && accountBalance.queryState == 1">
                <div style="color: #444444;font-size: 20px;display: flex;justify-content: center;margin-top: 20px;font-weight: bold;">
                    <div>{{accountBalance.info.acct_balance}} 元</div>
                </div>
                <div>
                    <div style="margin-top: 10px;">
                        <div style="margin: 0 0 20px 48px" @click="checkoutLeft(9, 1)" class="hand op" :class="link == 9 ? 'select-btn' : 'checkout-btn'">提现</div>
                        <div style="margin: 0 0 20px 48px" @click="checkoutLeft(10, 1)" class="hand op" :class="link == 10 ? 'select-btn' : 'checkout-btn'">转账</div>
                    </div>
                </div>
            </div>
            <div v-if="user.userType == 2 && accountBalance.queryState == 1">
                <div style="color: #444444;font-size: 20px;display: flex;justify-content: center;margin-top: 20px;font-weight: bold;">
                    <div>{{accountBalance.info.acct_balance}} 元</div>
                </div>
                <div>
                    <div style="margin-top: 10px;">
                        <div style="margin: 0 0 20px 48px" @click="checkoutLeft(9, 1)" class="hand op" :class="link == 9 ? 'select-btn' : 'checkout-btn'">提现</div>
                        <div style="margin: 0 0 20px 48px" @click="checkoutLeft(10, 1)" class="hand op" :class="link == 10 ? 'select-btn' : 'checkout-btn'">转账</div>
                    </div>
                </div>
            </div>
          <div style="margin-top: 10px">
            <div style="width: 300px;border-bottom: 1px solid #CCCCCC;"></div>
          </div>
          <div style="margin-top: 10px">
            <div v-for="(item, index) in navList" :key="index" class="hand op nav-box" :class="link === item.link ? 'select-box' : ''" @click="function() {utils.a('/user/index/' + item.link); link = item.link}">
                <img :src="link === item.link ? item.select : item.icon" style="width: 28px; height: 28px; margin-left: 80px; margin-top: 17px; margin-right: 12px">
                <div>{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
      <div style="float: right;height: 810px; width: 1190px; background-color: #FFFFFF">
        <!-- 我的订单 -->
        <OrderNoList v-if="link === 1" :type="payType"></OrderNoList>
        <!-- 安全设置 -->
        <Security v-if="link === 2" @change="changeLink"></Security>
        <!-- 提现记录 -->
        <ExtractRecord v-if="link === 3"></ExtractRecord>
        <!-- 收益流水 -->
        <PullFlow v-if="link === 4"></PullFlow>
        <!-- 支出流水 -->
        <PushFlow v-if="link === 5"></PushFlow>
        <!-- 银行卡管理 -->
        <BankCard v-if="link === 6" :activeName="activeName" @change="getAccountBalance"></BankCard>
        <!-- 收货地址 -->
        <Address v-if="link === 7"></Address>
        <!-- 发票管理 -->
        <InvoiceManage v-if="link === 8"></InvoiceManage>
        <!-- 提现 -->
        <PutMoney v-if="link == 9" :acctBalance="acctBalance" @change="getAccountBalance"></PutMoney>
        <!-- 转账 -->
        <TransferMoney v-if="link === 10" :type="payType"></TransferMoney>
      </div>
    </div>
  </div>
</template>

<script>
import AvatarMenu from "@/components/common/AvatarMenu";
import Security from "@/pages/user/components/Security.vue";
import ExtractRecord from "@/pages/user/components/ExtractRecord.vue";
import PullFlow from "@/pages/user/components/PullFlow.vue";
import PushFlow from "@/pages/user/components/PushFlow.vue";
import BankCard from "@/components/common/Pay/bankCard.vue";
import Address from "@/pages/user/components/Address.vue";
import InvoiceManage from "@/pages/user/components/InvoiceManage.vue";
import PutMoney from "@/components/common/Pay/PutMoney.vue";
import TransferMoney from "@/components/common/Pay/TransferMoney.vue";
import OrderNoList from "@/components/common/Pay/orderNoList.vue";
export default {
  name:'Money',
  components: {
    TransferMoney,
    PutMoney,
    InvoiceManage,
    Address,
    BankCard,
    PushFlow,
    PullFlow,
    ExtractRecord,
    Security,
    AvatarMenu,
    OrderNoList,
  },
  data() {
    return {
          navList: [
            {link: 1, name: '我的订单', icon: require('./imgs/no1.png'), select: require('./imgs/money1.png')},
            {link: 2, name: '安全设置', icon: require('./imgs/no2.png'), select: require('./imgs/money2.png')},
            {link: 3, name: '提现记录', icon: require('./imgs/no3.png'), select: require('./imgs/money3.png')},
            {link: 4, name: '收益流水', icon: require('./imgs/no4.png'), select: require('./imgs/money4.png')},
            {link: 5, name: '支出流水', icon: require('./imgs/no5.png'), select: require('./imgs/money5.png')},
            {link: 6, name: '银行卡管理', icon: require('./imgs/no6.png'), select: require('./imgs/money6.png')},
            {link: 7, name: '收货地址', icon: require('./imgs/no7.png'), select: require('./imgs/money7.png')},
            {link: 8, name: '发票管理', icon: require('./imgs/no8.png'), select: require('./imgs/money8.png')},
            {link: 12, name: '账务流水', icon: require('./imgs/no12.png'), select: require('./imgs/money12.png')},
          ],
          type: 'home',
          name: "2",
          link: 1,
          payType: 0,
          accountBalance: {
              acct_balance: '',
              individ:{queryState:-2},
              corp:{queryState:-2}
          },
          activeName: '',
          acctBalance:{
              info:{acct_balance:0},
          },
    }
  },
  props:{
  },
  computed:{
    user() {
      return this.$store.state.user
    },
  },
  created(){
  },
  mounted() {
      if(this.urlType() != 'prod'){
          var params = {link: 11, name: '会员初始化', icon: require('./imgs/no11.png'), select: require('./imgs/money11.png')};
          this.navList.push(params);
      }
    this.getAccountBalance();
    this.link = Number(this.$route.params.id);
    if(this.user.userType == 1){
        this.activeName = 'first';
    }else if(this.user.userType == 2){
        this.activeName = 'second';
    }
  },
  watch: {
  },
  methods:{

      handleClick() {
          this.link = 1;
      },

      //更改link的值
      changeLink(index){
          this.link = index;
      },

    // 切换提现，转账
    checkoutLeft(e, type) {
          var that = this;
          if(this.user.realNameStatus != 2 ){
            return this.utils.msg("请先完成实名认证");
          }

          if(e == 9){
              if(that.activeName == 'first'){
                  that.acctBalance = that.accountBalance;
                  that.acctBalance.accType = 1;
              }else if (that.activeName == 'second'){
                    that.acctBalance = that.accountBalance;
                    that.acctBalance.accType = 2;
              }
          }

          this.link = e;
          this.utils.a('/user/index/' + e)
          this.payType = type;
    },

    //获取账户余额
    getAccountBalance(data) {
        var that = this;
        if(typeof data != 'undefined'){
            that.link = data.link;
        }
        that.newApi.getAccountBalance({}).then((res) => {
            if (res.isSuccess == 1) {
                that.accountBalance = res.data;
                if(typeof data != 'undefined'){
                    if(data.accType == 1){
                        that.acctBalance = that.accountBalance;
                        that.acctBalance.accType = 1;
                    }else if (data.accType == 2) {
                        that.acctBalance = that.accountBalance;
                        that.acctBalance.accType = 2;
                    }
                }
            }
        })
    }
  },


}
</script>
<style>
.el-tabs__header{
    margin: 0px;
    padding: 10px 0 0 10px;
}
</style>

<style scoped>
.member {
  min-height: 100vh;
  background-color: #F2F2F2;
}

.member .body {
  width: 1500px;
  margin: 10px auto
}

.member .nav-box {
  display: flex;
  color: #A6A9B7;
  font-size: 18px;
  font-weight: 600;
  height: 60px;
  line-height: 60px;
}

.member .select-box {
  background-color: #F1F5F8;
  color: #031F88;
}

.checkout-btn {
  width: 200px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  border-radius: 5px;
  padding: 10px 0;
  color: #EE3F4D;
  border: 1px solid #EE3F4D;
}

.select-btn {
  width: 200px;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  border-radius: 5px;
  padding: 10px 0;
  color: #FFFFFF;
  background-color: #EE3F4D;
  border: 1px solid #EE3F4D;
}

</style>
