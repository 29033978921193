<template>
    <div class="address" style="padding: 30px 40px">
        <div class="hand op btn-title" @click="addAddress">新增收货地址</div>
        <div v-if="editShow === true">
            <el-form ref="address" :model="address" :rules="addressRules" label-width="100px" style="width: 600px">
                <el-form-item label="收货地址" prop="deliveryScope">
                    <div style="display: flex">
                        <div>
                            <AddressSelect @change="selectCity" :address ="address.deliveryScope" :lv="3" :info="true" :multiple="false"></AddressSelect>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="收货人姓名" prop="name">
                    <el-input v-model="address.name"></el-input>
                </el-form-item>
                <el-form-item label="手机号码" prop="phone">
                    <el-input v-model="address.phone"></el-input>
                </el-form-item>
                <el-checkbox v-model="address.isDefault" :true-label="1" :false-label="0">默认收货地址</el-checkbox>
            </el-form>

            <div class="hand op" v-if="edit === false" @click="saveAddress" style="margin-top: 30px; width: 140px; height: 24px; padding: 8px 0; text-align: center; background-color: #1479FF; color: #FFFFFF">保存地址</div>
            <div class="hand op" v-if="edit === true" @click="updateAddress" style="margin-top: 30px; width: 140px; height: 24px; padding: 8px 0; text-align: center; background-color: #1479FF; color: #FFFFFF">修改地址</div>
        </div>

        <div style="margin-top: 50px" v-if="addressData.length > 0">
            <div class="btn-title">已保存的地址</div>
            <el-table
                    border
                    :data="addressData"
                    style="width: 100%">
                <el-table-column
                        label="收货人"
                        width="140"
                        align="center">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="收货地址"
                        width="360"
                        align="center">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">
                            {{ scope.row.deliveryScope.cityName[0] }}省
                            {{ scope.row.deliveryScope.cityName[1] }}市
                            {{ scope.row.deliveryScope.cityName[2] }}
                            {{ scope.row.deliveryScope.address }}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column
                        label="联系电话"
                        width="180"
                        align="center">
                    <template slot-scope="scope">
                        <span style="margin-left: 10px">{{ scope.row.phone }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="160" align="center">
                    <template slot-scope="scope">
                        <span class="hand op" @click="editAddress(scope.row)" style="color: #1479FF;">编辑</span>
                        <span style="margin: 0 10px">|</span>
                        <span class="hand op" @click="deleteAddress(scope.row.id)">删除</span>
                    </template>
                </el-table-column>
                <el-table-column width="160" align="center">
                    <template slot-scope="scope">
                        <span class="hand op" @click="setDefaultAddress(scope.row.id)" v-if="scope.row.isDefault === 0" style="color: #1479FF">设为默认地址</span>
                        <span v-if="scope.row.isDefault === 1" style="background-color: #1479FF; padding: 2px 6px; border-radius: 30px; color: #FFFFFF">默认地址</span>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import AddressSelect from "../../../components/common/city/AddressSelect";
    export default {
        name: "Address",
        components: {AddressSelect},
        computed:{
            user(){
                return this.$store.state.user;
            }
        },
        data() {
            return {
                addressData: [],

                address: {
                    name: '',
                    deliveryScope: {},
                    phone: '',
                    isDefault: 0,
                },

                addressRules: {
                    deliveryScope: [
                        { required: true, message: '请输入详细地址', trigger: 'blur' },
                    ],
                    name: [
                        { required: true, message: '请输入收货人姓名', trigger: 'blur' },
                    ],
                    phone: [
                        { required: true, message: '请输入手机号码', trigger: 'blur' },
                        { pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }
                    ],
                },

                editShow: false,
                edit: false
            }
        },

        mounted() {
            this.getAddress()
        },

        methods: {
            // 设置默认地址
            setDefaultAddress(id) {
                this.newApi.setDefaultAddress({id: id}).then(res => {
                    if (res.isSuccess === 1) {
                        this.utils.sus(res.data)
                        this.getAddress()
                    }
                })
            },

            // 获取收货地址
            getAddress() {
                this.newApi.getAddressList().then(res => {
                    this.addressData = res.data
                })
            },

            // 保存收货地址
            saveAddress() {
                if (!this.address.deliveryScope.cityName || !this.address.deliveryScope.address) {
                    this.utils.err('请填写收货地址')
                    return false;
                }
                this.$refs['address'].validate((valid) => {
                    if (valid) {
                        this.address.deliveryScope = JSON.stringify(this.address.deliveryScope)
                        this.newApi.addAddress(this.address).then(res => {
                            if (res.isSuccess === 1) {
                                this.utils.sus(res.data);
                                this.editShow = false;
                                this.getAddress()
                            }
                        })
                    }
                })
            },

            // 更新地址
            updateAddress() {
                this.$refs['address'].validate((valid) => {
                    if (valid) {
                        let params = {
                            id: this.address.id,
                            name: this.address.name,
                            phone: this.address.phone,
                            isDefault: this.address.isDefault,
                            deliveryScope: JSON.stringify(this.address.deliveryScope)
                        }
                        this.newApi.updateAddress(params).then(res => {
                            if (res.isSuccess === 1) {
                                this.utils.sus(res.data);
                                this.editShow = false;
                                this.edit = false;
                                this.getAddress()
                            }
                        })
                    }
                })
            },

            // 选择地址
            selectCity(item) {
                this.address.deliveryScope = item
            },

            changeAddress() {
                this.address.region = this.$refs['myCascader'].getCheckedNodes()[0].pathLabels
            },

            // 添加地址
            addAddress() {
                if(this.user.realNameStatus != 2 ){
                    return this.utils.msg("请先完成实名认证");
                }
                this.editShow = true;
                this.edit = false;
                this.address = {
                    name: '',
                    deliveryScope: {},
                    phone: '',
                    isDefault: 0,
                }
            },

            // 编辑
            editAddress(item) {
                this.editShow = true;
                this.edit = true;
                this.address = item
            },

            // 删除
            deleteAddress(id) {
                let that = this;
                this.utils.confirm('确认删除此收货地址？', function () {
                    that.newApi.deleteAddress({id: id}).then(res => {
                        if (res.isSuccess === 1) {
                            that.utils.sus(res.data);
                            that.getAddress()
                        }
                    })
                })
            },
        }
    }
</script>

<style scoped>
    .address .btn-title {
        font-size: 18px;
        font-weight: 600;
        color: #2970FF;
        margin-bottom: 20px;
    }

    .address .title {
        font-size: 16px;
        font-weight: 600;
    }
</style>
